import * as React from "react";

const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={38.061}
    height={38.061}
    fill="currentColor"
    {...props}
  >
    <path
      data-name="Path 47"
      d="M23.87 23.036c-2.893-1.78-6.564-2.169-10.792-1.223a.688.688 0 0 0 .333 1.335c3.895-.89 7.177-.557 9.792 1.057a.674.674 0 1 0 .667-1.169Z"
    />
    <path
      data-name="Path 48"
      d="M25.261 19.809a16.273 16.273 0 0 0-12.517-1.5.844.844 0 1 0 .5 1.613 14.417 14.417 0 0 1 11.07 1.28.838.838 0 0 0 1.168-.279.785.785 0 0 0-.221-1.114Z"
    />
    <path
      data-name="Path 49"
      d="M19.03 0A19.031 19.031 0 0 0 0 19.031a19.03 19.03 0 0 0 38.061 0A19.032 19.032 0 0 0 19.03 0Zm0 30.157a11.126 11.126 0 1 1 11.126-11.126A11.126 11.126 0 0 1 19.03 30.157Z"
    />
    <path
      data-name="Path 50"
      d="M12.076 14.58a1.02 1.02 0 0 0 .612 1.947c3.394-1.057 9.346-.834 12.906 1.28a1.167 1.167 0 0 0 1.447-.278 1.077 1.077 0 0 0-.389-1.447c-4.117-2.447-10.626-2.673-14.576-1.502Z"
    />
  </svg>
);

export default SvgComponent;
