import React from "react";
import Box from "./box";
import Flex from "./flex";
import Button from "./button";

import Facebook from "../images/facebook";
import Spotify from "../images/spotify";
import Instagram from "../images/instagram";
import Youtube from "../images/youtube";
import Tiktok from "../images/tiktok";
import styled from "styled-components";

const StyledButton = styled(Button)`
  @media all and (max-width: 768px) {
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    display: inline-block;
    overflow: visible;
    padding: 1em 0.5em;
  }
`;

const StyledLink = styled(Box)`
  margin-bottom: 0.25em;
  &:visited,
  &:link {
    display: inline-block;
    color: #5b5c50;
  }
  &:hover {
    color: white;
  }
`;

const ArtistLinks = ({ links }) => (
  <Box position="absolute" top="0" right="0" height="100%">
    <Flex
      position="sticky"
      mt={5}
      top={5}
      alignSelf="flex-start"
      textAlign="center"
      flexDirection="column"
    >
      {links.facebook && (
        <StyledLink as="a" href={links.facebook} target="_blank">
          <Facebook />
        </StyledLink>
      )}
      {links.instagram && (
        <StyledLink as="a" href={links.instagram} target="_blank">
          <Instagram />
        </StyledLink>
      )}
      {links.spotify && (
        <StyledLink as="a" href={links.spotify} target="_blank">
          <Spotify />
        </StyledLink>
      )}
      {links.tiktok && (
        <StyledLink as="a" href={links.tiktok} target="_blank">
          <Tiktok />
        </StyledLink>
      )}
      {links.youtube && (
        <StyledLink as="a" href={links.youtube} target="_blank">
          <Youtube />
        </StyledLink>
      )}
      {links.link && (
        <StyledButton as="a" href={links.link} mt={5}>
          {links.button_text}
        </StyledButton>
      )}
    </Flex>
  </Box>
);

export default ArtistLinks;
