import * as React from "react";

const Tiktok = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={38.061}
    height={38.061}
    fill="currentColor"
    {...props}
  >
    <path d="M19 0C8.5 0 0 8.5 0 19s8.5 19 19 19 19-8.5 19-19c.1-10.5-8.5-19-19-19zm9.8 16.9h-.6c-2.1 0-4-1-5.1-2.8v9.4c0 3.8-3.1 6.9-6.9 6.9s-6.9-3.1-6.9-6.9 3.1-6.9 6.9-6.9h.4V20h-.4c-2 0-3.5 1.6-3.5 3.5 0 2 1.6 3.5 3.5 3.5 2 0 3.7-1.5 3.7-3.5V7.6h3.3c.3 2.9 2.7 5.2 5.6 5.4v3.9z" />
  </svg>
);

export default Tiktok;
