import React from "react";
import Box from "./box";
import Flex from "./flex";
import ArtistBox from "./artistbox";
import { Link } from "gatsby";
import styled from "styled-components";

const StyledLink = styled(Link)`
  &:link,
  &:visited {
    color: currentColor;
    text-decoration: none;
  }
`;

const H2 = styled("h2")`
  font-size: 5.3vw;
  margin-bottom: 0;

  @media (max-width: 768px) {
    font-size: 8vw;
  }
`;

const ArtistRow = ({ title, artists }) => {
  return (
    <Flex alignItems="center" mx={-3} flexWrap="wrap">
      {Object.keys(artists).map((obj, i) => {
        return (
          <Box width={[1, 1 / 3]} key={i} px={3} py={[3, null]}>
            <StyledLink to={`/artistes/${artists[obj].slug}`}>
              <ArtistBox name={artists[obj].name} image={artists[obj].image} />
            </StyledLink>
          </Box>
        );
      })}
    </Flex>
  );
};

export default ArtistRow;
