import Box from "./box";
import styled from "styled-components";

const Button = styled(Box)`
  display: inline-block;
  background: #5b5c50;
  padding: 0.5em 1em;
  color: #68695c;
  text-decoration: none;
  border-radius: 2em;
  &:hover {
    background: rgba(255, 255, 255, 0.15);
  }
`;
export default Button;
