import React from "react";
import styled from "styled-components";
import Box from "./box";

const ArtistImage = styled(Box)`
  border-radius: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  padding-top: 100%;
  > img {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    transition: transform 0.6s ease;
    max-width: 100%;
    transform: scale(1.05);
    transform-origin: center center;
  }
`;

const ArtistBoxStyled = styled(Box)`
  border: 1px solid #68685d;
  text-align: center;
  padding: 1em;
  position: relative;

  &:before {
    display: block;
    position: absolute;
    width: 100%;
    height: 0;
    background: rgba(0, 0, 0, 0.25);
    content: "";
    bottom: 0;
    left: 0;
    transition: height 0.3s ease;
    z-index: 0;
  }

  &:hover {
    ${ArtistImage} img {
      transform: scale(1);
    }

    &:before {
      height: 100%;
    }
  }
`;

const ArtistBoxInner = styled(Box)`
  position: relative;
`;

const ArtistBox = ({ name, image }) => {
  return (
    <ArtistBoxStyled>
      <ArtistBoxInner>
        <ArtistImage>
          <img src={image} alt={name} />
        </ArtistImage>
        <Box as="h3" style={{ fontSize: "24px" }} my={(1, 1, 1, 3)}>
          {name}
        </Box>
      </ArtistBoxInner>
    </ArtistBoxStyled>
  );
};

export default ArtistBox;
