import * as React from "react";

const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={38.061}
    fill="currentColor"
    height={38.061}
    {...props}
  >
    <path
      data-name="Path 45"
      d="M19.03 0A19.031 19.031 0 0 0 0 19.029a19.03 19.03 0 0 0 38.061 0A19.032 19.032 0 0 0 19.03 0Zm10.7 25.227a3.041 3.041 0 0 1-1.975 2.276c-1.742.538-8.726.538-8.726.538s-6.984 0-8.726-.538a3.04 3.04 0 0 1-1.974-2.276 41.333 41.333 0 0 1 0-12.392 3.04 3.04 0 0 1 1.974-2.276c1.742-.538 8.726-.538 8.726-.538s6.984 0 8.726.538a3.041 3.041 0 0 1 1.975 2.276 41.424 41.424 0 0 1 0 12.392Z"
    />
    <path data-name="Path 46" d="M15.05 23.56v-9.058l7.96 4.527Z" />
  </svg>
);

export default SvgComponent;
